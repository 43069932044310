import { useContext, useState, useEffect } from "react";
import { State } from "../context/stateContext";

export default function ClientDetails() {
  const { clientName, clientAddress, contact, invoiceDate } = useContext(State);
  const [invoiceNo, setInvoiceNo] = useState(""); 
  const [showWarranty, setShowWarranty] = useState(true);

  const toggleWarranty = () => {
    setShowWarranty(!showWarranty);
  };

  useEffect(() => {
    
    const generateInvoiceNumber = () => {
      // Generate a random 6-digit number
      const randomNumber = Math.floor(100000 + Math.random() * 900000);

      const invoiceNumber = `SP${randomNumber}`;
      localStorage.setItem("invoiceNo", invoiceNumber);

      setInvoiceNo(invoiceNumber);
    };

    generateInvoiceNumber(); 

  }, []); 

  return (
    <section className="ml-6">
      <section className="flex mt-20">
        Invoice To &nbsp; : &nbsp;&nbsp;<h2 className="text-sm font-bold mt-1">{clientName}</h2><br/>
      </section>
      <section className="flex">
        Address &nbsp; : &nbsp;&nbsp;<p className="text-sm mt-1">{clientAddress}</p><br/> 
      </section>
      <section className="flex">
        Contact &nbsp; : &nbsp;&nbsp;<p className="text-sm mt-1">{contact}</p><br/> 
      </section>
      <section className="flex">
        Invoice Number &nbsp; : &nbsp;&nbsp;<p className="text-sm mt-1">{invoiceNo}</p><br/> 
      </section>

      <section className="ml-9 text-red-600 font-bold">
        <section className="flex mt-5">
          Invoice Date &nbsp; : &nbsp;&nbsp;<p className="text-sm"><p className="mt-1">{invoiceDate}</p></p><br/> 
        </section>

        <div className="mr-4 -ml-6">
          <label className="text-gray-800 text-md font-bold ml-6 show-details-label">
            10 Years Warranty
            <input
              type="checkbox"
              checked={showWarranty}
              onChange={toggleWarranty}
              className="ml-2"
            />
          </label>
        </div>
        {showWarranty && (
          <p className="text-red-500 font-bold">10 years warranty </p>
        )}
        
        <hr className="border-t border-gray-400 my-4"/>

      </section>
    </section>
  );
}
